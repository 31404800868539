import React from 'react'
import Layout from '../../staticcomponents/layout'
import './termsofuse.scss'
import TermsOfUseHeader from './termsofuseheader'
import Container from '@material-ui/core/Container';


export default function TermsOfUse() {
    return (
        <>
            <Layout>
            <Container maxWidth="xl" className='terms-use-container-div'>
                <TermsOfUseHeader />
            </Container>
            </Layout>
        </>
    )
}