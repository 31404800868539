import React from 'react'
import Grid from '@material-ui/core/Grid';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import './TermsOfUseHeader.scss'

export default function TermsOfUseHeader() {
  // dropdown open     
  const [loginEl, setloginEl] = React.useState(null);
  const loginOpen = Boolean(loginEl);
  const loginClick = (event) => {
    setloginEl(event.currentTarget);
  };
  const loginClose = () => {
    setloginEl(null);
  };

  return (
    <>
      {/* new starts */}
      <Grid container className="termsofuse-header-cls">
        <Grid item xl={1} lg={1} md={0} sm={0} xs={0}></Grid>


        <Grid item container xl={10} lg={11} md={12} sm={12} xs={12}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h2 className='header-title'>terms of use</h2>
            <p className='sub-title'>UNITED STATES</p>
            <p className='para-title'>what you should know</p>
          </Grid>

          <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className='terms-reverse-column'>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
              {/* one */}
              <p className='paragraph'>Welcome to the Cartus web site (the "Web Site"). The goal of this Web Site is to provide you with
                access to the most comprehensive network of relocation-related information, services, and related links to meet your needs.
                Please read these Terms of Use (the "Terms") carefully before continuing on with your use of this Web Site. These Terms
                shall govern the use of the Web Site and apply to all Internet traffic visiting the Web Site. By accessing or using this
                Web Site, you agree to the Terms. The Terms are meant to protect all of our Web Site visitors and your use of this Web Site
                signifies your agreement with these Terms. IF YOU DO NOT AGREE WITH THESE TERMS, DO NOT USE THIS WEB SITE. Cartus
                Corporation (Cartus) reserves the right, in its sole discretion, to modify, alter or otherwise update these Terms at
                any time. Such modifications shall be effective immediately upon posting. By using this Web Site after we have posted
                notice of such modifications, alterations or updates you agree to be bound by such revised Terms.</p>
              <p className='paragraph'> In accordance with
                our goals, this Web Site will permit you to link to several other web sites, that may or may not be affiliated with
                this Web Site and/or Cartus, and that may have terms of use that differ from, or contain terms in addition to, the terms
                specified here. Your access to such web sites through links provided on this Web Site is governed by the terms of use and
                policies of those sites, not this Web Site.</p>

              {/* two */}
              <p className='para-title'>privacy</p>
              <p className='paragraph'>Registration data and certain other information about you is subject to our Privacy Policy.
                For more information, please review our full <span className='terms-anchor-link '>Privacy Policy</span>.</p>

              {/* three */}
              <p className='para-title'>trademarks, copyrights, and restrictions</p>
              <p className='paragraph'>This Web Site is controlled and operated by Cartus, 40 Apple Ridge Road, Danbury, CT 06810.
                All content on this Web Site, including, but not limited to, text, images, illustrations, audio clips, and video clips,
                is protected by copyrights, trademarks, service marks, and/or other intellectual property rights (which are governed by
                U.S. and worldwide copyright laws and treaty provisions, privacy and publicity laws, and communication regulations and
                statutes), and are owned and controlled by Cartus or its affiliates, or by third party content providers, merchants,
                sponsors and licensors (collectively “Providers”) that have licensed their content or the right to market their products
                and/or services to Cartus. Cartus and the Cartus logo are pending or registered trademarks of Cartus Corporation.
                Top Block® is a registered trademark of USAA. Content on this Web Site or any web site owned, operated, licensed or
                controlled by the Providers is solely for your personal, non-commercial use. You may print a copy of the content and/or
                information contained herein for your personal, non-commercial use only, but you may not copy, reproduce, republish,
                upload, post, transmit, distribute, and/or exploit the content or information in any way (including by e-mail or other
                electronic means) for commercial use without the prior written consent of Cartus or the Providers. You may request
                consent by faxing a request to Legal Affairs at (203) 205-6575. Without the prior written consent of Cartus or the
                Providers, your modification of the content, use of the content on any other web site or networked computer environment,
                or use of the content for any purpose other than personal, non-commercial use, violates the rights of the owners of
                the copyrights, trademarks or service marks and other proprietary rights, and is prohibited. As a condition to your
                use of this Web Site, you warrant to Cartus that you will not use this Web Site for any purpose that is unlawful or
                prohibited by these Terms, including without limitation the posting or transmitting any threatening, libelous,
                defamatory, obscene, scandalous, inflammatory, pornographic, or profane material. If you violate any of these Terms,
                your permission to use this Web Site immediately terminates without notice. Cartus retains the right to deny access to
                anyone at its discretion for any reason, including for violation of these Terms. You may not use on your web site any
                trademarks, service marks or copyrighted materials appearing on this Web Site, including, but not limited to, any
                logos or characters, without the express written consent of the owner of the mark or copyright. You may not frame
                or otherwise incorporate into another web site any of the content or other materials on this Web Site without
                prior written consent of Cartus.</p>

              {/* four */}
              <p className='para-title'>links</p>
              <p className='paragraph'>This Web Site may contain links to other web sites (“Linked Sites”). The Linked Sites are
                provided for your convenience and information only and, as such, you access them at your own risk. The content of any
                Linked Sites is not under Cartus’ control, and Cartus is not responsible for, and does not endorse, such content,
                whether or not Cartus is affiliated with the owners of such Linked Sites. You may not establish a hyperlink to this
                Web Site or provide any links that state or imply any sponsorship or endorsement of your web site by Cartus, or
                its affiliates or Providers.</p>

              {/* five */}
              <p className='para-title'>disclaimer of warranties and liability</p>
              <p className='paragraph'>ALL CONTENT ON THIS WEB SITE IS PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY KIND EITHER
                EXPRESS OR IMPLIED. OTHER THAN THOSE WARRANTIES WHICH, UNDER U.S. LAWS APPLICABLE TO THESE TERMS, ARE IMPLIED BY
                LAW AND ARE INCAPABLE OF EXCLUSION, RESTRICTION, OR MODIFICATION, CARTUS DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                NEITHER CARTUS ITS AFFILIATED OR RELATED ENTITIES, NOR THE PROVIDERS, NOR ANY PERSON INVOLVED IN THE CREATION,
                PRODUCTION, AND DISTRIBUTION OF THIS WEB SITE WARRANT THAT THE FUNCTIONS CONTAINED IN THIS WEB SITE WILL BE UNINTERRUPTED
                OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVER THAT MAKES THE CONTENT AVAILABLE WILL BE FREE
                OF VIRUSES OR OTHER HARMFUL COMPONENTS. THE CONTENT THAT YOU ACCESS ON THIS WEB SITE IS PROVIDED SOLELY FOR YOUR
                CONVENIENCE AND INFORMATION. CARTUS DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE RESULTS THAT
                MAY BE OBTAINED FROM THE USE OF THIS WEB SITE, OR AS TO THE RELIABILITY, ACCURACY OR CURRENCY OF ANY INFORMATION
                CONTENT, SERVICE AND/OR MERCHANDISE ACQUIRED PURSUANT TO YOUR USE OF THIS WEB SITE. YOU EXPRESSLY AGREE THAT
                USE OF THIS WEB SITE IS AT YOUR SOLE RISK. YOU (AND NOT CARTUS) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING,
                REPAIR OR CORRECTION OF YOUR SYSTEM. YOU EXPRESSLY AGREE THAT NEITHER CARTUS OR ITS AFFILIATED OR RELATED ENTITIES
                (INCLUDING ITS PROVIDERS), NOR ANY OF THEIR RESPECTIVE EMPLOYEES, OR AGENTS, NOR ANY PERSON OR ENTITY INVOLVED IN
                THE CREATION, PRODUCTION AND DISTRIBUTION OF THIS WEB SITE, IS RESPONSIBLE OR LIABLE TO ANY PERSON OR ENTITY
                WHATSOEVER FOR ANY LOSS, DAMAGE (WHETHER ACTUAL, CONSEQUENTIAL, PUNITIVE OR OTHERWISE), INJURY, CLAIM, LIABILITY
                OR OTHER CAUSE OF ANY KIND OR CHARACTER WHATSOEVER BASED UPON OR RESULTING FROM THE USE OR ATTEMPTED USE OF THIS
                WEB SITE OR ANY OTHER LINKED SITE. BY WAY OF EXAMPLE, AND WITHOUT LIMITING THE GENERALITY OF THE FOREGOING,
                CARTUS AND RELATED PERSONS AND ENTITIES SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY CLAIM OR DAMAGE ARISING FROM
                FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION, COMPUTER VIRUS,
                THEFT, DESTRUCTION, UNAUTHORIZED ACCESS TO OR ALTERATION OF PERSONAL RECORDS, OR THE RELIANCE UPON OR USE OF
                DATA, INFORMATION, OPINIONS OR OTHER MATERIALS APPEARING ON THIS WEB SITE. YOU EXPRESSLY ACKNOWLEDGE AND
                AGREE THAT CARTUS IS NOT LIABLE OR RESPONSIBLE FOR ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER
                SUBSCRIBERS OR THIRD PARTIES. SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
                CONSEQUENTIAL OR INCIDENTAL DAMAGES. IN SUCH JURISDICTIONS, CARTUS’ LIABILITY IS LIMITED TO THE GREATEST
                EXTENT PERMITTED BY LAW. YOU FURTHER ACKNOWLEDGE THAT YOU ARE SOLELY RESPONSIBLE FOR ANY MOBILE DEVICE THAT
                YOU UTILIZE TO ACCESS THIS WEB SITE, INCLUDING MANAGING ANY DATA RETAINED OR DOWNLOADED TO THAT DEVICE.</p>

              {/* six */}
              <p className='para-title'>indemnification</p>
              <p className='paragraph'>You agree to indemnify, defend, and hold harmless Cartus and the Providers, its and their
                officers, directors, employees, affiliates, agents, licensors, and suppliers from and against all losses, expenses,
                damages and costs, including reasonable attorneys’ fees, resulting from any violation by you of these Terms.</p>

              {/* seven */}
              <p className='para-title'>third-party rights</p>
              <p className='paragraph'>These Terms are for the benefit of Cartus and its Providers, its and their officers,
                directors, employees, affiliates, agents, licensors, and suppliers. Each of these individuals or entities shall
                have the right to assert and enforce these Terms directly against you on its or their own behalf.</p>

              {/* eighth */}
              <p className='para-title'>jurisdictional issues</p>
              <p className='paragraph'>Unless otherwise specified, the content contained in this Web Site is presented solely for
                your convenience and/or information. This Web Site is controlled and operated by Cartus from its offices in Danbury,
                Connecticut, USA. Cartus makes no representation that content in this Web Site is appropriate or available for use
                in other locations. Those who choose to access this Web Site from other locations do so on their own initiative and
                are responsible for compliance with local laws, if and to the extent local laws are applicable. You may not use or
                export the materials in this Web Site in violation of U.S. export laws and regulations. These Terms shall be governed
                by, construed and enforced in accordance with the laws of the State of Connecticut as they are applied to agreements
                entered into and to be performed entirely within such State. Any action you, any third party or Cartus brings to
                enforce these Terms, or in connection with any matters related to this Web Site, shall be brought only in either
                the state or Federal courts located in or for Fairfield County, Connecticut, and you expressly consent to the
                jurisdiction of said courts. If any provision of these Terms shall be unlawful, void or for any reason unenforceable,
                then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability
                of any remaining provisions.</p>

              {/* nine */}
              <p className='para-title'>entire agreement</p>
              <p className='paragraph'>The provisions and conditions of these Terms, and each obligation referenced herein, represent
                the entire Agreement between Cartus its affiliated or related entities, and you, and supersede any prior agreements or
                understandings not incorporated herein. In the event that any inconsistencies exist between these Terms and any future
                published terms of use or understanding, the last published Terms or terms of use or understanding shall prevail.</p>

              {/* tenth */}
              <p className='bold-paragraph'>ANY RIGHTS NOT EXPRESSLY GRANTED HEREIN ARE RESERVED BY CARTUS.</p>

            </Grid>

            <Grid item container xl={4} lg={4} md={4} sm={12} xs={12} className='terms-use-dropdown'>            
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="dropdown-div">
                <Button
                  id="logins"
                  aria-controls={loginOpen ? 'login-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={loginOpen ? 'true' : undefined}
                  onClick={loginClick}
                  className="navbar-btns"
                >
                  <span className="dropdown-txt-bold">terms of use: USA</span>
                  {loginOpen ?
                    <ExpandLessIcon className="expand-icons-desk" />
                    : <ExpandMoreIcon className="expand-icons-desk" />}
                </Button>
                <Menu
                  id="login-menu"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  anchorEl={loginEl}
                  open={loginOpen}
                  onClose={loginClose}
                  MenuListProps={{
                    'aria-labelledby': 'logins',
                  }}
                >
                  <MenuItem onClick={loginClose} className="menu-items">
                    pre-decision services 1</MenuItem>
                  <MenuItem onClick={loginClose} className="menu-items">
                    pre-decision services 2</MenuItem>
                  <MenuItem onClick={loginClose} className="menu-items">
                    pre-decision services 3</MenuItem>
                </Menu>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="para-title-xs-col">
                <p className='para-title-xs'>what you should know</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={1} lg={0} md={0} sm={0} xs={0}></Grid>
      </Grid>
      {/* new ends */}
    </>
  )
}


